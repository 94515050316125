import type { FormInstance } from 'antd';
import { message } from 'antd';
import * as echarts from 'echarts';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import _ from 'lodash';
import { action, observable, runInAction } from 'mobx';
import React from 'react';
import { api } from '../../utils';
import type { GoodsList, NewTab, NoticeData, Response, ShopExpire, ShortCutEdit, ShortCutList, SystemData, TenantRechargeExpire } from './interface';
import AlarmModel from './components/alarm/model';

const blueAreaStyle = {
  normal: {
    // 渐变填充色（线条下半部分）
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: '#1978FF',
      },
      {
        offset: 1,
        color: '#1978FF00',
      },
    ]),
  },
};

const orignAreaStyle = {
  normal: {
    // 渐变填充色（线条下半部分）
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: '#FF670D',
      },
      {
        offset: 1,
        color: '#FF670D00',
      },
    ]),
  },
};

export class DashboardStore {
  @observable public todoData = {
    waitCheck: 0, // 待审核
    waitPrint: 0, // 待打印
    waitReceive: 0, // 待验货
    unPrintBefore2Day: 0, // 超过2天未发货
    undelivered: 0, // 上次发货失败
    purchasedUnPrint: 0, // 采购完成未打印
    notProxySendNum: 0, // 未代发数
    returnOrderNum: 0, // 待退货数
  };

  @observable public shortCutData: ShortCutEdit = {
    current: [],
    surplus: [],
  };

  @observable public shortCutEditData: ShortCutEdit = {
    current: [],
    surplus: [],
  };

  @observable public isShowEdit = false;

  @observable public noticeList: NoticeData[] = [];

  @observable public systemUpdateData: SystemData = {
    title: '',
    content: [],
  };

  @observable public feedBacktext = '';

  @observable public feedBackphone = '';

  @observable public feedBackformRef = React.createRef<FormInstance>();

  @observable public isShowShopExpire = false;

  @observable public showTopSaleData = true;

  @observable public isPrewarn = false; // 预警

  @observable public shopExpireList: ShopExpire[] = [];

  @observable public tenantRechargeExpire: TenantRechargeExpire = {};

  @observable public defaultActiveKey = 'deliveryOptions';

  @observable public topSaleData = [];

  @observable public topSaleInstance;

  @observable public deliveryInstance;

  @observable public showWarnModal = false;

  @observable public warnContent: string;

  @observable public goodsVendorId = null;

  @observable public baseOptions = {
    color: [
      '#1978FF',
      '#FF670D',
      '#1978FF',
    ],
    legend: {
      show: true,
      type: 'plain',
      right: 27,
      top: 0,
      icon: 'roundRect',
      itemHeight: 3,
      itemWidth: 14,
      data: [],
    },
    tooltip: {
      trigger: 'axis',

      // formatter: '{b}<br />{a} {c}件',
      axisPointer: {
        type: 'line',

        // lineStyle: { width: 10 },
        label: { width: 10 },
      },
    },
    xAxis:
      {
        // splitLine: { show: false },
        data: [],
        boundaryGap: false, // 防止统计图左侧和纵轴有间隙
        // splitLine: { show: true,lineStyle: {color: '#333',opacity: 0.05}},
        // axisLine: { show: true ,lineStyle: {color: '#333',opacity: 0.1 }},
        axisTick: {
          show: true,
          lineStyle: { color: '#c5c3cb' },
        },
      },

    yAxis: [
      {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.05,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.1,
          },
        },
        axisTick: {
          show: true,
          lineStyle: { color: '#c5c3cb' },
        },
      },
      {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.05,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.1,
          },
        },
        axisTick: {
          show: true,
          lineStyle: { color: '#c5c3cb' },
        },
      },
    ],

    grid: { // 图表的位置
      left: 23,
      top: 34,
      right: 22,
      bottom: 10,
      containLabel: true, // false是依据坐标轴来对齐的,true是依据坐标轴上面的文字边界来对齐
    },

    series:
      {
        name: '',
        type: 'line',
        showSymbol: false,
        smooth: true,
        data: [],
        areaStyle: blueAreaStyle,
      },
  };

  @observable public deliveryOptions = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '发货单数',
        '发货件数',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '发货单数',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: '发货件数',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,

      },
    ],

  };

  @observable public orderOptions = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '销售单',
        '销售金额',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '销售单',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: '销售金额',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,

      },
    ],

  };

  @observable public todayDelivery = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '发货单数',
        '发货件数',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '发货单数',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: '发货件数',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,

      },
    ],

  };

  @observable public payedNotDelivery = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '订单数量',
        'sku数量',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '订单数量',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: 'sku数量',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,
      },
    ],
  };

  @observable public topSale = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: '订单数量',
    },
    series: {
      showSymbol: false,
      smooth: true,
      areaStyle: blueAreaStyle,
      type: 'line',
      name: '订单数量',
      yAxisIndex: 0,
      data: [
        1,
        21,
        3,
      ],
    },

  };

  // 图表
  @observable public tabsData = [
    {
      id: 'deliveryOptions',
      name: '发货量',
      option: this.deliveryOptions,
      url: api.delivergoods,
      showData: true,
    },
    {
      id: 'orderOptions',
      name: '订单量',
      option: this.orderOptions,
      url: api.salegoods,
      showData: true,
    },
    {
      id: 'todayDelivery',
      name: '当天发货',
      option: this.todayDelivery,
      url: api.todaygoods,
      showData: true,
    },
    {
      id: 'payedNotDelivery',
      name: '已付款未发货',
      option: this.payedNotDelivery,
      url: `${api.payedAndNotDelivery}?type=softWare`,
      showData: true,
    },
  ];

  @observable public domAD; // 是否展示左上方选款广告

  @observable public carouselRef;

  @observable public picList = []; // 为你推荐前4个商品

  @observable public alarmModel = new AlarmModel();

  @action public setDomRef = (el) => {
    this.domAD = el;
  };

  @action public setNewCarousel = (el: unknown): void => {
    this.carouselRef = el;
  };

  @action public getAlarm = async() => {
    const req = await request<BaseData<Partial<{
      hours: number;
      orders: number;
      totalPrice: number;
    }>>>({
      method: 'GET',
      url: '/api/pms/pay/rest/payableOrder/alarm',
    });
    this.alarmModel.onOpen(req.data?.hours, req.data?.totalPrice);
  };

  // 获取待办事项量
  public queryTodoData = action(() => {
    request(({ url: api.orderTodo }))
      .then((res: Response) => {
        const { data } = res;
        this.todoData = {
          ...this.todoData,
          ...data,
        };
      });
    request(({ url: api.deliveryTodo }))
      .then((res: Response) => {
        const { info } = res;
        this.todoData = {
          ...this.todoData,
          waitCheck: info['1'],
          waitPrint: info['2'],
          waitReceive: info['7'],
          notProxySendNum: info['20'],
        };
      });
  });

  // 获取未代发数
  @action public queryNotProxySendNum = async(): Promise<void> => {
    const res: PaginationData = await request({
      url: api.notProxySendNum,
      method: 'post',
      data: {
        page: 1,
        pageSize: 10,
        proxySendStatus: 1,
        countOnly: true,
      },
    });
    this.todoData = {
      ...this.todoData,
      notProxySendNum: res.data.totalCount,
    };
  };

  // 获取未退货数
  @action public queryReturnOrderNum = async(): Promise<void> => {
    const res: BaseData<number> = await request({ url: api.returnOrderNum });
    this.todoData = {
      ...this.todoData,
      returnOrderNum: res.data,
    };
  };

  // 获取为你推荐前4个商品
  @action public queryLayoutGoodsVo = async(shopId: number): Promise<void> => {
    if (shopId) {
      const res: BaseData<{ goodsList: GoodsList[]; goodsVendorId: number; }> = await request({ url: `${api.findVendorRecentlyGoodsInfo}?shopId=${shopId}` });
      this.picList = res.data?.goodsList?.map((item) => item.mainPicUrl);
      this.goodsVendorId = res.data.goodsVendorId;
    } else {
      const res: BaseData<GoodsList[]> = await request({ url: `${api.queryPromotionGoods}?goodsNum=3` });
      this.picList = res.data?.map((item) => item.mainPicUrl);
    }
  };

  // 获取dashbord页面的一些数据
  public queryCommonInfo = action(() => {
    request({ url: `${api.queryCommonInfo}?homePageType=1` })
      .then((res: Response) => {
        const {
          systemNotice,
          shortcut,
          updateNotice,
        } = res.data;
        this.shortCutData.current = shortcut;
        this.systemUpdateData = updateNotice || {};
        this.noticeList = systemNotice || [];
      });
  });

  // 图表
  public queryOrderDelivery = action(() => {
    this.tabsData.map((item) => {
      request({ url: item.url })
        .then((res: Response) => {
          const { status } = res;
          if (status === 'NoModuleAuth') {
            item.showData = false;
          }

          const {
            xAxisData,
            numData,
            data,
          } = res.data;

          this[item.id].xAxis = {
            ...this[item.id].xAxis,
            data: xAxisData,
          };
          this[item.id].series = [
            {
              ...this[item.id].series[0],
              data: data.data,
            },
            {
              ...this[item.id].series[1],
              data: numData.data,
            },
          ];
          this.deliveryInstance.updateEChartsOption();
        })
        .catch((res: Response) => {
          message.destroy();
          if (res.data && res.data.status && res.data.status === 'NoModuleAuth') {
            item.showData = false;
          }
        });
    });
  });

  public getDeliveryRefData = (e) => {
    this.deliveryInstance = e;
  };

  public getTopSaleInstance = (e) => {
    this.topSaleInstance = e;
  };

  public queryTopSale = action(() => {
    request({ url: api.getSevenDaysTopProducts })
      .then((res: Response) => {
        const result = res.data.map((item) => {
          return {
            ...item,
            option: {
              ...this.topSale,
              xAxis: {
                ...this.topSale,
                boundaryGap: false,
                data: item.xAxisData,
              },
              series: {
                ...this.topSale.series,
                data: item.numData,
              },
            },
          };
        });
        this.showTopSaleData = true;

        this.topSaleData = result;
      })
      .catch((e) => {
        message.destroy();
        this.showTopSaleData = false;
      });
  });

  // 快捷入口切换弹窗显隐
  public toggleShortcutEdit = action((isShow: boolean) => {
    this.isShowEdit = isShow;
    if (isShow) {
      this.shortCutEditData = this.shortCutData;
      this.queryMenuList();
    }
  });

  // 快捷入口的菜单列表
  public queryMenuList = action(() => {
    request({
      url: api.getAllMenus,
      method: 'POST',
      data: { homePageType: 1 },
    })
      .then((res: ShortCutList[]) => {
        console.log('menu:', res);
        if (!res) {
          return;
        }
        const resultData = res.map((item) => {
          const filterMenu = item.menus.filter((child) => {
            const index = _.findIndex(this.shortCutData.current, [
              'id',
              child.id,
            ]);
            index > -1 && this.handleShortCutCurrentData(index, item.id);
            return index === -1;
          });
          return {
            ...item,
            menus: filterMenu,
          };
        });
        this.shortCutData.surplus = resultData;
        console.log('shor...', this.shortCutData);
      });
  });

  // 给已经选择的快捷方式添加父级ID
  public handleShortCutCurrentData(index, id) {
    const data: ShortCutList[] = this.shortCutData.current;
    data[index] = {
      ...data[index],
      parentId: id,
    };
    this.shortCutData.current = data;
  }

  // 快捷入口添加删除操作
  public handleShortCutEdit = action((item, type, parentId, index): void => {
    const {
      surplus,
      current,
    } = this.shortCutEditData;
    const isAdd = type === 'ADD';
    if (isAdd) {
      if (current.length === 10) {
        message.destroy();
        message.info('最多添加10个');
        return;
      }

      // 从菜单目录里去除要添加的快捷项
      const chooseMenu = surplus[index];
      const menus = chooseMenu.menus.filter((menu) => menu.id !== item.id);
      const result = surplus;
      result[index] = {
        ...chooseMenu,
        menus,
      };
      this.shortCutEditData = {
        surplus: result,
        current: [
          ...current,
          {
            ...item,
            parentId,
          },
        ],
      };
    } else {
      const index = _.findIndex(surplus, [
        'id',
        item.parentId,
      ]);
      const chooseMenu = surplus[index];
      const menus = [
        ...chooseMenu.menus,
        item,
      ];
      const result = surplus;
      result[index] = {
        ...chooseMenu,
        menus,
      };

      this.shortCutEditData = {
        surplus: result,
        current: current.filter((i) => i.id !== item.id),
      };
    }
  });

  // 快捷入口保存
  public handleShortCutSave = action(() => {
    const { current } = this.shortCutEditData;
    const params = current.map((item) => item.id);
    request({
      method: 'post',
      url: api.saveShortcut,
      data: { ids: params.join(',') },
    })
      .then(() => {
        this.shortCutData = this.shortCutEditData;
        this.toggleShortcutEdit(false);
      })
      .catch((res: Response) => {
        message.error(res.data.data);
      });
  });

  // 快捷入口跳转
  public handleShortCutOpenTab = (item: NewTab): void => {
    // 销售开单等菜单要携带仓库ID
    const warehouseMenu = sessionStorage.getItem('warehouseMenu');
    const warehouseList = warehouseMenu ? JSON.parse(warehouseMenu) : [];
    const currentWareHouse = sessionStorage.getItem('currentWareHouse');
    const url = warehouseList.includes(Number(item.id)) ? `${item.url}?shopAndWarehouseId=${currentWareHouse}` : item.url;
    window.top.egenie.openTab(url, item.id, item.name, item.icon);
  };

  // 联系客服
  public handleCopy = (phone) => {
    const ele: Partial<HTMLInputElement> = document.getElementById('copyText');
    ele.value = phone;

    ele.select();
    document.execCommand('copy');
  };

  public handleSubmit = (values): void => {
    console.log('values...', values);
    const {
      feedBackphone,
      feedBacktext,
    } = values;
    if (!feedBacktext || !feedBackphone) {
      message.error('信息填写不全');
      return null;
    }
    request({
      method: 'post',
      url: api.commitFeedback,
      data: {
        content: feedBacktext,
        mobile: feedBackphone,
      },
    })
      .then((res: Response) => {
        message.success('反馈成功');
        this.feedBackformRef.current.setFieldsValue({
          feedBackphone: '',
          feedBacktext: '',
        });
      })
      .catch(() => {
        message.destroy();
        message.error('手机号格式错误');
      });
  };

  // 店铺过期
  public getShopExpireList = action(() => {
    request({ url: api.shopExpireList })
      .then((res: Response) => {
        if (!res.data) {
          return;
        }
        this.shopExpireList = res.data;
        this.toggleShopExpire(true);
      });
  });

  public handleShopAgain = action((shopId) => {
    request({ url: `${api.shopAgain}/${shopId}` })
      .then((res: Response) => {
        window.open(res.data);
        this.toggleShopExpire(false);
      });
  });

  public toggleShopExpire = action((isShow: boolean) => {
    this.isShowShopExpire = isShow;
  });

  @action public mainPre = (): void => {
    this.carouselRef.prev();
  };

  @action public mainNext = (): void => {
    this.carouselRef.next();
  };

  @action public getRechargeStatistics = async(): Promise<void> => {
    try {
      const res: BaseData<TenantRechargeExpire> = await request({ url: '/api/tenant/tenantRecharge/statistics' });
      this.tenantRechargeExpire = res.data;
    } catch (err) {
      console.error(err);
    }
  };

  @action public getWarn = (): void => {
    request<BaseData<{ tips?: string; warn?: boolean; }>>({
      url: '/api/rms/erp/yilianbaitiao/overdue/warn',
      method: 'POST',
      data: {},
    })
      .then((res) => {
        runInAction(() => {
          this.warnContent = res?.data?.tips;
          this.showWarnModal = res?.data?.warn;
        });
      });
  };

  @action public hiddenModal = (): void => {
    this.showWarnModal = false;
  };
}

export default new DashboardStore();
